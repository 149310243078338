import React, { useEffect, useState } from 'react';
import PricingPlan from './PricingPlan';
import useUserCurrency from '../../hooks/useUserCurrency';
import usePrices from '../../hooks/usePrices';
import useLocalStorage from '../../hooks/useLocalStorage';
import { loadStripe } from '@stripe/stripe-js';
import { Alert } from 'flowbite-react';
import { set } from 'react-ga';

const stripePromise = loadStripe('pk_live_51MDLQfDsTL5MPVicZ13cYgFnsV9AzlfmzG7ZKFJZc91koRCV94AtN5ZxIErEy75FJj6CLejEMl3gQETlap9X27Li008qyfDhCK'); // Replace with your actual publishable key

function CustomPricingTable({
  user,
  pricingOption, 
  setIsAlert, 
  setAlertMessage, 
  setAlertSeverity, 
  defaultPricingOption,
  saleBannerColour,
  salePercentage,
  saleDuration,
}) {
  const currency = useUserCurrency();
  let isSubscriptions = false;
  if (pricingOption !== 'oneoff'){
    isSubscriptions = true;
  }

  const getVariant = () => {
    const variants = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
    return variants[Math.floor(Math.random() * variants.length)];
  };

  const prices = usePrices(currency, isSubscriptions);
  const [variant, setVariant] = useLocalStorage('subscriptionPriceAmount', getVariant());
  const [variant2, setVariant2] = useLocalStorage('oneoffPriceAmount', getVariant());
  


  let [buttonName, setButtonName] = useState('Buy Now');

  let mode = 'subscription'; // Change to 'payment' for one-time payments
  const url = 'https://frozen-springs-61240.herokuapp.com';


  let subscriptionHobbyist = 500;
  let subscriptionSongwriter = 1500
  let subscriptionProfessional = 5000;

  let oneoffHobbyist = 750;
  let oneoffSongwriter = 2000;
  let oneoffProfessional = 7500;

  let priceChoice = 'C';

  if (priceChoice === 'A') {
    subscriptionHobbyist= 500; //done
    subscriptionSongwriter = 1500; //done
    subscriptionProfessional = 5000; //done
    oneoffHobbyist = 750; //done
    oneoffSongwriter = 2000; //done
    oneoffProfessional = 7500; //done

  } else if (priceChoice === 'B') {
    subscriptionHobbyist = 499; //done
    subscriptionSongwriter = 1499; //done
    subscriptionProfessional = 4999; //done

    oneoffHobbyist = 799;
    oneoffSongwriter = 1999;
    oneoffProfessional = 7999;

  } else if (priceChoice === 'C') {
    subscriptionHobbyist = 490;
    subscriptionSongwriter = 1400; //done
    subscriptionProfessional = 4900;

    oneoffHobbyist = 790;
    oneoffSongwriter = 1900;
    oneoffProfessional = 7900;

  } else if (priceChoice === 'D') {
    subscriptionHobbyist = 480;
    subscriptionSongwriter = 1300; //done
    subscriptionProfessional = 4800; //done

    oneoffHobbyist = 780;
    oneoffSongwriter = 1800;
    oneoffProfessional = 7800;

  } else if (priceChoice === 'E') {
    subscriptionHobbyist = 900;
    subscriptionSongwriter = 2400;
    subscriptionProfessional = 8900;

    oneoffHobbyist = 1400;
    oneoffSongwriter = 2900;
    oneoffProfessional = 13900;

  } else if (priceChoice === 'F') {
    subscriptionHobbyist = 900;
    subscriptionSongwriter = 2400;
    subscriptionProfessional = 9900;

    oneoffHobbyist = 1500;
    oneoffSongwriter = 3000;
    oneoffProfessional = 14900;

  } else if (priceChoice === 'G') {
    subscriptionHobbyist = 2900;
    subscriptionSongwriter = 8900;
    subscriptionProfessional = 29000;

    oneoffHobbyist = 4400;
    oneoffSongwriter = 12000;
    oneoffProfessional = 39000;

  } else if (priceChoice === 'H') {
    subscriptionHobbyist = 1200;
    subscriptionSongwriter = 3400;
    subscriptionProfessional = 11900;

    oneoffHobbyist = 1900;
    oneoffSongwriter = 4900;
    oneoffProfessional = 19000;

  } else {
    subscriptionHobbyist = 500; //done
    subscriptionSongwriter = 1500; //done
    subscriptionProfessional = 5000; //done

    oneoffHobbyist = 750; //done
    oneoffSongwriter = 2000; //done
    oneoffProfessional = 7500; //done
  }

  const captureExperiment = async (product, priceAmount) => {
    await fetch(`${url}/experiment-results`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        experimentName: 'pricing_page',
        experimentDescription: "Various different pricing page tests",
        variants: {
          defaultPricingOption, // Ensure this is correctly referenced
          pricingOption,
          product: product.name,
          currency,
          user: user ? user._id : null,
          saleBannerColour,
          salePercentage,
          buttonName,
          saleDuration,
          priceAmount,
          pricingVariant: variant,
        },        
        action: "click",
      })
    })
    .catch((error) => {
    });
  }


  
  const filterPrices = (price) => {
    if (isSubscriptions && ((price.product.name === "Hobbyist" && price.unit_amount === subscriptionHobbyist) || (price.product.name === "Songwriter" && price.unit_amount === subscriptionSongwriter) || (price.product.name === "Professional" && price.unit_amount === subscriptionProfessional))){
      return price.recurring;
    } else if (!isSubscriptions && ((price.product.name === "Hobbyist" && price.unit_amount === oneoffHobbyist) || (price.product.name === "Songwriter" && price.unit_amount === oneoffSongwriter) || (price.product.name === "Professional" && price.unit_amount === oneoffProfessional))){
      return !price.recurring;
    }
  }


  const handleSelectPlan = async (priceId, priceRecurring, product, priceAmount) => {
    try {
      if (!priceRecurring){
        mode = 'payment';
      }

      await captureExperiment(product, priceAmount);

      if (!user){
        setIsAlert(true);
        setAlertMessage('Please sign in to continue');
        setAlertSeverity('error');
        window.location.href = '/signin';
        return;
      }
      if (user?.customer?.stripeSubscriptionStatus === 'active'){
        setIsAlert(true);
        setAlertMessage('You already have an active subscription');
        setAlertSeverity('error');
        return;
      }

      
      const response = await fetch(`${url}/create-checkout-session`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-access-token': user.token },
        body: JSON.stringify({ priceId, customer: user.customer.stripeId, mode, product: product.name }),
      });
      const data = await response.json();

      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId: data.sessionId });
    } catch (error) {
      console.error('Error during checkout:', error);
      // Display error message to user
    }
  };

  return (
    <div className="flex flex-col lg:flex-row justify-center items-center">
      {prices.length > 0 ? (
        prices.filter((price) => filterPrices(price)).sort((a, b)=> parseFloat(a.unit_amount) - parseFloat(b.unit_amount)).map((price) => (
          <PricingPlan
            key={price.id}
            name={price.product.name}
            price={price.currency_options[currency.toLowerCase()]?.unit_amount || price.unit_amount}
            currency={currency.toUpperCase()}
            features={price.product.features || []}
            onSelect={() => handleSelectPlan(price.id, price.recurring, price.product, price.unit_amount)}
            isSubscriptions={isSubscriptions}
            mostPopular={price.product.name === 'Songwriter'}
            salePercentage={salePercentage}
            buttonName={buttonName}
            setButtonName={setButtonName}
          />
        ))
      ) : (
        <p className="text-white text-xl">Loading prices...</p>
      )}
    </div>
  );
}

export default CustomPricingTable;
