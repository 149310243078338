import styles from "./Homescreen/Homescreen.module.css";
import LandingPageTemplate from "./LandingPages/LandingPageTemplate";
import { useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";


export default function LandingPage() {

  const getVariant = () => {
    const variants = ['A', 'B'];
    return variants[Math.floor(Math.random() * variants.length)];
  };

  const getVariant2 = () => {
    const variants = ['A', 'B', 'C', 'D'];
    return variants[Math.floor(Math.random() * variants.length)];
  };
  
  const [variant, setVariant] = useLocalStorage('h1-3', getVariant());
  const [variant2, setVariant2] = useLocalStorage('h2-2', getVariant2());

let h1;
let h2;

if (variant === 'A') {
  h1 = "Your Songwriting Journey Just Got a Whole Lot Easier with AI.";
} else if (variant === 'B') {
  h1 = "Generate perfect song lyrics and music with AI";
} else {
  h1 = "Your Songwriting Journey Just Got a Whole Lot Easier with AI.";
}


h2 = "Transform your music creation process with our AI-powered song lyric generator";



const title = "AI Music Generator";
const keywords = "rap lyrics generator, lyric generator, music generator, music, songwriting, songwriting assistant, songwriting app, songwriting platform, songwriting software, songwriting tool, songwriting tools, songwriting website, how to write lyrics";
const description = "Master songwriting with our AI Music and Lyric Generators";
const longDescription = "Lyrical Labs is a songwriting platform equipped with an AI Lyrics Generator, and an AI music generator, designed to inspire your lyrics and beats, catering to your style and flow needs!";

const sectionHeader = "The Ultimate Songwriting Companion";
const featureTitle1 = "Limitless creativity";
const featureTitle2 = "Your personal songwriting assistant";
const featureTitle3 = "In-built smart dictionary";
const featureTitle4 = "Write in any language";
const featureParagraph1 = "Endless songwriting inspiration awaits with a never-ending supply of original ideas and melodies. The AI can write about any topic, and generate music of any genre";
const featureParagraph2 = "Collaborate with our AI songwriting partner to craft the perfect song. Once you have lyrics and music, you can provide feedback by talking to the AI to bring your musical vision to life.";
const featureParagraph3 = "The platform comes equipped with an in-built smart dictionary that helps you find all sorts of related words to provide inspiration and help you finish your lyrics.";
const featureParagraph4 = "With over 100+ languages to choose from, you can learn how to write song lyrics in any language!";
const faq1 = "Do I keep the copyright?";
const faq2 = "Is the goal of the platform to completely write my songs for me?";
const faq3 = "How does the platform help me overcome writer's block?";
const faq4 = "How does the platform help with boosting my songwriting process?";
const faqa1 = "Yes! Lyrical Labs is 100% royalty free, so you keep all the rights to the music and lyrics you create on this platform.";
const faqa2 = "No, the goal of our platform is to provide you with an endless supply of inspiration and to help overcome writer's block. While the AI is capable of generating complete sets of lyrics and musical melodies, we encourage you to use them as a starting point and add your own personal touch. The AI is here to assist and inspire you, not to replace your own creativity.";
const faqa3 = "Writer's block can be a frustrating and demoralizing experience for any songwriter. But with our platform, you'll never have to worry about running out of ideas. Simply input your desired theme and style, and the AI will provide you with an endless stream of creative and original ideas to work with. This can help kickstart your songwriting process and get you back on track.";
const faqa4a = "Our platform is designed to provide you with an endless supply of ideas and suggestions based on your chosen stylistic preferences, giving you the freedom to experiment and try something new.";
const faqa4b = "Additionally, the platform allows you to write and edit the lyrics at your own will, with a highly unique 'Related Words' dictionary by your side to assist you throughout your songwriting process. It's like your own personal creative assistant, fit to your own personal tastes and songwriting needs!";
const quote1 = "The lyrics and beats it generates are on point. A true innovation for artists.";
const quote2 = "This music generator has elevated my songwriting process. Total game-changer.";
const quote3 = "Incredible tool for any artist looking for fresh inspiration and beats.";


  return (
    <LandingPageTemplate
    title={title}
    keywords={keywords}
    description={description}
    longDescription={longDescription}
    h1={h1}
    h2={h2}
    sectionHeader={sectionHeader}
    featureTitle1={featureTitle1}
    featureTitle2={featureTitle2}
    featureTitle3={featureTitle3}
    featureTitle4={featureTitle4}
    featureParagraph1={featureParagraph1}
    featureParagraph2={featureParagraph2}
    featureParagraph3={featureParagraph3}
    featureParagraph4={featureParagraph4}
    faq1={faq1}
    faq2={faq2}
    faq3={faq3}
    faq4={faq4}
    faqa1={faqa1}
    faqa2={faqa2}
    faqa3={faqa3}
    faqa4a={faqa4a}
    faqa4b={faqa4b}
    quote1={quote1}
    quote2={quote2}
    quote3={quote3}
    />
  )
}
