import React, { useEffect, useState, useRef } from 'react'
import CustomPricingTable from '../components/Stripe/CustomPricingTable';
import { Select, MenuItem } from '@mui/material';
import Countdown from 'react-countdown';
import useLocalStorage from '../hooks/useLocalStorage';

function PricingPage({user, subscriptionActive, setIsAlert, setAlertMessage, setAlertSeverity}) {

  const getVariant = () => {
    const variants = ['A', 'B'];
    return variants[Math.floor(Math.random() * variants.length)];
  };

  const getVariant2 = () => {
    const variants = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
    return variants[Math.floor(Math.random() * variants.length)];
  };

  const getVariant3 = () => {
    const variants = ['A', 'B', 'C'];
    return variants[Math.floor(Math.random() * variants.length)];
  };

  const getVariant4 = () => {
    const variants = ['A', 'B'];
    return variants[Math.floor(Math.random() * variants.length)];
  };

  const [variant, setVariant] = useState(getVariant());
  const [variant2, setVariant2] = useState(getVariant2());
  const [variant3, setVariant3] = useLocalStorage('salePercentage2', getVariant3());
  // const [variant3, setVariant3] = useState(getVariant3());
  const [variant4, setVariant4] = useLocalStorage('saleDuration2', getVariant4());
  // const [variant4, setVariant4] = useState(getVariant4());


  let [pricingOption, setPricingOption] = useState('oneoff');

  let saleBannerColour;

  saleBannerColour = "bg-yellow-500";


  let salePercentage;

  salePercentage = 80;


  let saleDuration;


  saleDuration = 'month';


  const [defaultPricingOption, setDefaultPricingOption] = useState('oneoff');
  useEffect(() => {
    if (variant === 'A') {
      setPricingOption('subscriptions');
      setDefaultPricingOption('subscriptions');
    } else {
      setPricingOption('oneoff');
    }
  }, [variant]);


  function getEndOfMonth() {
    const now = new Date();
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    return endOfMonth;
  }

  function getEndOfWeek() {
    const now = new Date();
    const endOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() + (7 - now.getDay()));
    return endOfWeek;
  }

  function getEndOfDay() {
    const now = new Date();
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    return endOfDay;
  }
  
  function getSaleLabel() {
    if (saleDuration === 'month') {
      const now = new Date();
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      return `${monthNames[now.getMonth()]} Sale`;
    } else if (saleDuration === 'week') {
      return '7-day Sale';
    } else if (saleDuration === 'day') {
      return '24-hour Flash Sale';
    } else {
      return 'Sale';
    }
  }
  //181414

  function SaleBanner() {
    return (
      <div className={`${saleBannerColour} text-white font-bold p-5 text-center text-2xl my-4 rounded-[5px] shadow-[0_4px_6px_rgba(0,0,0,0.1)]`}>
        <span>
          {getSaleLabel()} - Save {salePercentage.toString()}%! Hurry up! Ends in{" "}
          <Countdown
            date={saleDuration === 'month' ? getEndOfMonth() : saleDuration === 'week' ? getEndOfWeek() : getEndOfDay()}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              if (completed) {
                return <span>0d 0h 0m 0s</span>;
              } else {
                return (
                  <span>
                    {days}d {hours}h {minutes}m {seconds}s
                  </span>
                );
              }
            }}
          />
        </span>
      </div>
    );
  }
  return (
    <>
    <div className="min-h-screen py-10 w-screen">
    <SaleBanner/>
    <div className="flex flex-col px-8 lg:px-36">
      <h1 className="text-4xl font-bold text-center text-white mb-12">Our Pricing Plans</h1>
      <Select   sx={{ 
            color: "white", 
            "&:before": { // underline color when not focused
              borderColor: "white",
            },
            "&:after": { // underline color when focused
              borderColor: "white",
            },
            "& .MuiSvgIcon-root": { // arrow icon color
              color: "white",
            }
          }} className="w-fit" value={pricingOption} onChange={(event) => setPricingOption(event.target.value)}>
            <MenuItem value='subscriptions'>Subscriptions</MenuItem>
            <MenuItem value='oneoff'>One-Off Payments</MenuItem>
          </Select>
          <p className="text-left text-sm mx-6 mt-4 font-bold tracking-tight text-white/80">
            Charged monthly. Cancel any time.
          </p>
          <Countdown
            date={saleDuration === 'month' ? getEndOfMonth() : saleDuration === 'week' ? getEndOfWeek() : getEndOfDay()}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              if (completed) {
                return <span>{getSaleLabel()} has ended.</span>;
              } else {
                return (
                  <span className="text-left text-sm mx-6 mt-4 font-bold tracking-tight text-white/80">
                    {getSaleLabel()} ends in {days}d {hours}h {minutes}m {seconds}s
                  </span>
                );
              }
            }}
          />
      <CustomPricingTable 
        className="mt-5" 
        user={user} 
        pricingOption={pricingOption} 
        subscriptionActive={subscriptionActive} 
        setIsAlert={setIsAlert} 
        setAlertMessage={setAlertMessage} 
        setAlertSeverity={setAlertSeverity} 
        defaultPricingOption={defaultPricingOption} 
        saleBannerColour={saleBannerColour}
        salePercentage={salePercentage}
        saleDuration={saleDuration}
        />
      </div>
    </div>
    </>
  );
}

export default PricingPage;
