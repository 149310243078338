import { useEffect, useState } from 'react';

function useUserCurrency() {
  const [currency, setCurrency] = useState('USD'); // Default currency

  useEffect(() => {
    async function fetchCurrency() {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        const dataCurrency = data.currency;
        setCurrency(dataCurrency || 'USD');
      } catch (error) {
        console.error('Error fetching user currency:', error);
      }
    }

    fetchCurrency();
  }, []);

  return currency;
}

export default useUserCurrency;
