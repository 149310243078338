import React, { useState } from 'react';
import './Modal.css';
import useLocalStorage from '../../hooks/useLocalStorage';


const Modal = ({ onClose, user }) => {
  const [emails, setEmails] = useState(['']);
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonStatus, setButtonStatus] = useState(true);
  const [invitedUsers, setInvitedUsers] = useState(false);
  const url = "https://frozen-springs-61240.herokuapp.com"
  const getVariant = () => {
    const variants = ['A', 'B', 'C', 'D'];
    return variants[Math.floor(Math.random() * variants.length)];
  };
  
  const getVariant2 = () => {
    const variants = ['A', 'B'];
    return variants[Math.floor(Math.random() * variants.length)];
  };
  
  const [variant, setVariant] = useLocalStorage('modalButton2', getVariant());
  const [variant2, setVariant2] = useLocalStorage('modalButtonColour2', getVariant());
  const [variant3, setVariant3] = useLocalStorage('isSaleNotice', getVariant2());

  let buttonVariant = "Unlock More Songs";


  let buttonColourVariant;
  let buttonHoverColourVariant;
  let buttonRingColourVariant;

  buttonColourVariant = "bg-indigo-500";
  buttonHoverColourVariant = "hover:bg-indigo-700";
  buttonRingColourVariant = "focus:ring-indigo-500";

  let isSaleNotice;

  isSaleNotice = true;


  const captureExperiment = () => {
    let variants;
    if (invitedUsers) {
      variants = {"invitedButton": "count"}
    } else {
      variants = {"button": buttonVariant, "buttonColour": buttonColourVariant, isSaleNotice}
    }

    fetch(`${url}/experiment-results`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        experimentName: 'modal',
        experimentDescription: "The modal when users reach the credit limit",
        variants,
        action: "click",
      })
    })
    .catch((error) => {
    });
  }

  const handleAddEmail = () => {
    setEmails([...emails, '']);
  };

  const copyToClipBoard = async copyMe => {
    try {
      await navigator.clipboard.writeText(copyMe);
    } catch (err) {
      console.log('Failed to copy!');
    }
  }

  const handleRemoveEmail = (index) => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    setEmails(newEmails);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setInvitedUsers(true);
    captureExperiment();
    const validEmails = emails.filter((email) => email !== '');
    if (validEmails.length === 0) {
      setErrorMessage('Please enter at least one email address');
      return;
    }
    // Call "/invite-users" endpoint with the array of emails in the request body
    // If the request is successful, call onClose() to close the modal
    // If the request fails, set the error message to the error message returned from the server
    const response = await fetch(`${url}/invite-users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': user.token,
      },
      body: JSON.stringify({
         emails: validEmails,
         invitedByName: user.name,
         invitedById: user._id,
         }),
    });
    if (response.ok) {
      onClose();
    } else {
      setErrorMessage("An error has occurred");
    }
    
  };

  return (
    <div className="modal-container">
      <div className="modal-content">
        <h2>You've reached the credit limit for a free user...</h2>
        <button className="close-button" onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div className="button-container">
          <button className={`
          ${buttonColourVariant}
          ${buttonHoverColourVariant}
          ${buttonRingColourVariant}
          text-white 
          px-2 
          py-5 
          mb-10 
          rounded 
          border-none 
          mt-5 
          text-lg 
          font-bold 
          cursor-pointer  
          `}
          onClick={() => {
            captureExperiment();
            window.location.href='/prices'
            }}>
            {buttonVariant}
          </button>
        </div>
        {isSaleNotice && (
          <div className="sale-notice">
            <h3 className="text-red-500">Our flash sale ends soon...</h3>
            <p className="text-red-500">See the <a onClick={captureExperiment} href="/prices">pricing page</a> for more info</p>
          </div>
        )}
        <hr className='border-white/5 mt-6'/>
        <div className="invite-container">
          <h2>Invite friends to get more credits instead!</h2>
          <div className="padding-bottom">
            <h4 className="text-sm">
              You will get more credits immediately, 
              and you will get <strong className='text-indigo-400'>5 credits</strong> for each friend who signs up with your link!
            </h4>
            <br></br>

            <p>Invite by link:</p>
            <div className="grid grid-cols-8 space-x-0">
              <input type="text" className="col-start-1 col-span-6 text-sm" value={`https://lyricallabs.io/register?invite=${user._id}`} readOnly onClick={() => copyToClipBoard(`https://lyricallabs.io/register?invite=${user._id}`)} />
              <button className="col-start-7 col-span-2 text-sm bg-indigo-700 rounded-md rounded-l-none" value="Click to copy" onClick={() => copyToClipBoard(`https://lyricallabs.io/register?invite=${user._id}`)}>Click to copy</button>
            </div>
          </div>
          <p>Invite by email:</p>
          <form onSubmit={handleSubmit}>
            {emails.map((email, index) => (
              <div key={index}>
                <div className="grid grid-cols-8 space-x-2">
                <input
                  type="email"
                  value={email}
                  onChange={(event) => {
                    const newEmails = [...emails];
                    newEmails[index] = event.target.value;
                    setEmails(newEmails);
                  }}
                  placeholder="Enter email address"
                  className="col-start-1 col-span-6 text-sm"
                  required
                />
                {index > 0 && (
                  <button type="button" onClick={() => handleRemoveEmail(index)} className="remove-button col-start-7 col-span-2 text-sm bg-indigo-700 rounded-md rounded-l-none">
                    Remove
                  </button>
                )}
                </div>
                
              </div>
            ))}
            <button type="button" onClick={handleAddEmail} className="add-button">
              Add another email
            </button>
            <div className="invite-button-container">
              <button disabled={!buttonStatus} type="submit" className="submit-button">
                Submit
              </button>
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Modal;
