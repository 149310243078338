import React, { useEffect, useState, useRef } from 'react'
import Footer from '../components/Footer';
import Countdown from 'react-countdown';
import { Select, MenuItem } from '@mui/material';




function StripePricingTable({ subscriptionData, setSubscriptionData, subscriptionActive, setSubscriptionActive, cancelClicked, setCancelClicked, user, setUser }) {
  const getVariant = () => {
    const variants = ['A', 'B'];
    return variants[Math.floor(Math.random() * variants.length)];
  };
  const pricingTableRef = useRef(null);


  const [variant, setVariant] = useState(getVariant());
  let [pricingOption, setPricingOption] = useState('oneoff');
  useEffect(() => {
    if (variant === 'A') {
      setPricingOption('subscriptions');
    } else {
      setPricingOption('oneoff');
    }
  }, [variant]);
  const [customer, setCustomer] = useState('');
  const URL = 'https://frozen-springs-61240.herokuapp.com';

  const captureExperiment = async () => {
    await fetch(`${URL}/experiment-results`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        experimentName: 'pricing_page',
        experimentDescription: "Various different pricing page tests",
        variants: {"pricingDefault": variant === 'A' ? "subscriptions" : "oneoff"},
        action: "click",
      })
    })
    .catch((error) => {
    });
  }
  useEffect(() => {
    const handleBeforeUnload = async () => {
      await captureExperiment();
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  
  useEffect(() => {
    const originalFetch = window.fetch;
    window.fetch = function (...args) {
      const [resource, config] = args;
      // Check if the resource URL matches the one you're interested in
      if (typeof resource === 'string' && resource.includes('checkout.stripe.com')) {
        captureExperiment();
      }
      return originalFetch.apply(this, args);
    };
  
    return () => {
      window.fetch = originalFetch; // Cleanup
    };
  }, []);
  
  useEffect(() => {
    const handlePricingTableClick = (event) => {
      console.log('Pricing table clicked', event);
      captureExperiment();
    };

    const pricingTableElement = pricingTableRef.current;
    if (pricingTableElement) {
      pricingTableElement.addEventListener('click', handlePricingTableClick);
    }

    return () => {
      if (pricingTableElement) {
        pricingTableElement.removeEventListener('click', handlePricingTableClick);
      }
    };
  }, [captureExperiment]);

  useEffect(() => {
    if (user && user.customer && user.customer.stripeSubscriptionStatus === 'Active' || user && user.customer && user.customer.stripeSubscriptionStatus === 'active') {
      setSubscriptionActive(true);
    }
    if (user) {
      const data = {
        customer: user.customer.stripeId,
      }
      
      fetch(`${URL}/get-client-secret`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(response => {
        if(response.ok){
          return response.json()
        } else {
          return {client_secret: ''}
        }
      }
      ).then(data => {
        setCustomer(data.client_secret);
      });
      fetch(`${URL}/log-visit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({user: user._id, page: 'pricing'})
      }).then(response => {
        if(response.ok){
          return response.json()
        } else {
          return {message: 'Error'}
        }
      }
      )
  }
  }, []);


  function getEndOfMonth() {
    const now = new Date();
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    return endOfMonth;
  }
  
  function getSaleLabel() {
    const now = new Date();
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return `${monthNames[now.getMonth()]} Sale`;
  }
  //181414

  function SaleBanner() {
    return (
      <div className="bg-[#ff5656] text-white font-bold p-5 text-center text-2xl my-4 rounded-[5px] shadow-[0_4px_6px_rgba(0,0,0,0.1)]">
        <span>
          {getSaleLabel()} - Save 50%! Hurry up! Ends in{" "}
          <Countdown
            date={getEndOfMonth()}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              if (completed) {
                return <span>0d 0h 0m 0s</span>;
              } else {
                return (
                  <span>
                    {days}d {hours}h {minutes}m {seconds}s
                  </span>
                );
              }
            }}
          />
        </span>
      </div>
    );
  }

  return (
    <>
      <div className="min-h-screen pt-12 w-screen bg-[#101010]">
        <SaleBanner />
        <section id="FAQ" className = "flex flex-row lg:flex-col px-8 lg:px-36 pb-16">
        <div className="rounded-md bg-white/5 mt-10 lg:px-10 lg:mx-12 mb-12">

          <h1 className="text-left text-3xl mx-6 mt-6 font-bold tracking-tight text-white">
            Select your plan
          </h1>
          <Select   sx={{ 
            color: "white", 
            "&:before": { // underline color when not focused
              borderColor: "white",
            },
            "&:after": { // underline color when focused
              borderColor: "white",
            },
            "& .MuiSvgIcon-root": { // arrow icon color
              color: "white",
            }
          }} value={pricingOption} onChange={(event) => setPricingOption(event.target.value)}>
            <MenuItem value='subscriptions'>Subscriptions</MenuItem>
            <MenuItem value='oneoff'>One-Off Payments</MenuItem>
          </Select>
          <p className="text-left text-sm mx-6 mt-4 font-bold tracking-tight text-white/80">
            Charged monthly. Cancel any time.
          </p>
          <Countdown
            date={getEndOfMonth()}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              if (completed) {
                return <span>{getSaleLabel()} has ended.</span>;
              } else {
                return (
                  <span className="text-left text-sm mx-6 mt-4 font-bold tracking-tight text-white/80">
                    {getSaleLabel()} ends in {days}d {hours}h {minutes}m {seconds}s
                  </span>
                );
              }
            }}
          />
          <div className='pt-10'/>

          { user ? 
             (pricingOption === 'subscriptions' ? 
              <div>
                <stripe-pricing-table ref={pricingTableRef} pricing-table-id="prctbl_1NumlXDsTL5MPVicfGNanMZD" customer-session-client-secret={customer}
                publishable-key="pk_live_51MDLQfDsTL5MPVicZ13cYgFnsV9AzlfmzG7ZKFJZc91koRCV94AtN5ZxIErEy75FJj6CLejEMl3gQETlap9X27Li008qyfDhCK">
                </stripe-pricing-table>
              </div>
              :
              <div>
                <stripe-pricing-table ref={pricingTableRef} customer-session-client-secret={customer} className="flex-row flex" pricing-table-id="prctbl_1NuVcEDsTL5MPVicUIApL0nP"
                  publishable-key="pk_live_51MDLQfDsTL5MPVicZ13cYgFnsV9AzlfmzG7ZKFJZc91koRCV94AtN5ZxIErEy75FJj6CLejEMl3gQETlap9X27Li008qyfDhCK">
                </stripe-pricing-table>
              </div>
              )
            
            :
             (
            //   pricingOption === 'subscriptions' ? 
            //       <stripe-pricing-table deactivate pricing-table-id="prctbl_1NumlXDsTL5MPVicfGNanMZD"
            //       publishable-key="pk_live_51MDLQfDsTL5MPVicZ13cYgFnsV9AzlfmzG7ZKFJZc91koRCV94AtN5ZxIErEy75FJj6CLejEMl3gQETlap9X27Li008qyfDhCK">
            //       </stripe-pricing-table>
            // :
            // <stripe-pricing-table className="flex-row flex" pricing-table-id="prctbl_1NuVcEDsTL5MPVicUIApL0nP"
            //   publishable-key="pk_live_51MDLQfDsTL5MPVicZ13cYgFnsV9AzlfmzG7ZKFJZc91koRCV94AtN5ZxIErEy75FJj6CLejEMl3gQETlap9X27Li008qyfDhCK">
            // </stripe-pricing-table>
            <div className="text-white font-bold text-2xl"><a href="/signin">Login</a> to see our pricing plans</div>
             
            )
          
        }

          <div className='pt-10'/>
          </div>


          </section>
      </div>
      <Footer/>
    </>
  )
}

export default StripePricingTable;