import React, { useState, useEffect } from 'react';
import { set } from 'react-ga';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../Footer';
import './App.css';
import Cancel from './Cancel';
import UnCancel from './UnCancel';

const AccountSubscription = ({ setUser, user, subscriptionKey, subscription, token, data, setData }) => {
  return (
    <div className="block my-2 text-sm font-medium text-gray-900 dark:text-white bg-white/5 rounded-md p-5">
      <h4>  
        <a className="text-sm mt-6" href={`/prices`}>
          {subscription.plan.nickname}
        </a>
      </h4>

      <p className='my-1'>
        Status: {subscription.status}
      </p>

      <p className='my-1'>
        Card: **** **** **** {subscription.default_payment_method?.card?.last4}
      </p>

      <p className='my-1'>
        Current period end: {(new Date(subscription.current_period_end * 1000).toString())}
      </p>

      <p className='my-1'>
        Cancel at period end: {subscription.cancel_at_period_end ? 'Yes' : 'No'}
      </p>

      {/* <Link to={{pathname: '/change-plan', state: {subscription: subscription.id }}}>Change plan</Link><br /> */}
      { subscription.cancel_at_period_end ? <UnCancel setUser={setUser} user={user} token={token} subscriptionId={subscription.id} data={data} setData={setData}/> :
      <Cancel setUser={setUser} user={user} token={token} subscriptionId={subscription.id} data={data} setData={setData}/>
      }
    </div>
  )
}

const Account = ({ setUser, user }) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [oneOffCustomer, setOneOffCustomer] = useState(false);
  const [remainingCalls, setRemainingCalls] = useState(0);
  const [data, setData] = useState(0);
  const url = "https://frozen-springs-61240.herokuapp.com";
  const navigate = useNavigate();
  useEffect(() => {
    if(!user){
      navigate('/signin');
      return;
    }
    const fetchData = async () => {
      const {subscriptions} = await fetch(`${url}/subscriptions`, {
        method: "GET",
        headers: {
          'x-access-token': user.token,
          'Content-Type': 'application/json',
        }
      }).then(r => r.json());

      setSubscriptions(subscriptions.data);
    }
    const updateUser = async () => {
      const updatedUser = await fetch(`${url}/update-user`, {
        method: "POST",
        headers: {
          'x-access-token': user.token,
          'Content-Type': 'application/json',
        },
      }).then(r => r.json());
      let newUser = updatedUser;
      newUser.token = user.token;
      setUser(newUser);
    }
    updateUser();
    fetchData();
  }, [data]);

  useEffect(() => {
    const remainingCalls = async () => {
      const remainingCalls = await fetch(`${url}/get-remaining-calls`, {
        method: "GET",
        headers: {
          'x-access-token': user.token,
          'Content-Type': 'application/json',
        }
      }).then(r => {
        if (r.ok){
          setOneOffCustomer(true);
          return r.json();
        } else {
          setOneOffCustomer(false);
          return false;
        }
      })
      try{
        if (remainingCalls.remainingCalls <= 0){
          setRemainingCalls(0);
        } else {
          setRemainingCalls(remainingCalls.remainingCalls);
        }
      } catch {
        setRemainingCalls(0);
      }
    }
    remainingCalls();
  }, []);

  if (!subscriptions) {
    return '';
  }

  return (
    <>
      <div className="pt-4 w-full min-h-screen bg-[#121212]">
        <main className="mt-20">
          <div className="w-screen">
            <div className="grid justify-items-center">
              <div className= "w-96 bg-white/5 border mb-20 border-white/10 rounded-lg shadow p-8">
                <h2 className="text-white/80 font-bold mb-6">Account Details</h2>
                <label for="name" className="block my-2 text-sm text-gray-900 dark:text-white">Name</label>
                <input type="name" name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white/5 dark:border-white/10 dark:placeholder-gray-400 dark:text-white" value={user.name} disabled></input>
                
                <label for="email" className="block my-2 mt-4 text-sm text-gray-900 dark:text-white">Email</label>
                <input type="email" name="email" id="email" className="bg-gray-50 border mb-6 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white/5 dark:border-white/10 dark:placeholder-gray-400 dark:text-white" value={user.email} disabled></input>
                <p className="text-white/80 text-sm">You have {remainingCalls} remaining credits</p>
                <hr className='border-white/5 mt-6'/>

                <div className="mt-2">
                  <h2 className="text-white/80 font-bold">Subscriptions</h2>
                  <div id="subscriptions">
                    {subscriptions.map(s => {
                      return <AccountSubscription setUser={setUser} user={user} key={s.id} subscription={s} token={user.token} data={data} setData={setData}/>
                    })}
                  </div>
                  {!subscriptions.length > 0 && <a className="text-xs" href="/prices">Add a subscription</a>}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer/>
    </>
  );
}

export default Account;
