import { useEffect, useState } from 'react';

function usePrices(currency, isSubscriptions) {
  const [prices, setPrices] = useState([]);

  const url = 'https://frozen-springs-61240.herokuapp.com';
  useEffect(() => {
    async function fetchPrices() {
      try {
        const response = await fetch(`${url}/config?currency=${currency}`);
        const data = await response.json();
        setPrices(data);
      } catch (error) {
        console.error('Error fetching prices:', error);
      }
    }

    if (currency) {
      fetchPrices();
    }
  }, [currency]);

  return prices;
}

export default usePrices;
