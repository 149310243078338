// Packages and Dependencies

import React, { useEffect, useRef, useState } from "react";
import { useTable, useSortBy } from 'react-table'
import styles from "./Homescreen.module.css";
import Footer from "../../components/Footer";
import { Button, Tooltip, Container, Grid } from "@mui/material";
import CopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/IosShare';
import ArrowLeft from '@mui/icons-material/ArrowCircleLeft';
import ArrowRight from '@mui/icons-material/ArrowCircleRight';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useLocalStorage from "../../hooks/useLocalStorage";
import { isRouteErrorResponse } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/Modal/Modal";
import ShareModal from "../../components/ShareModal/ShareModal";
import { Select, MenuItem } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(255, 255, 255, 0.3)',
    },
  },
});

export default function Homescreen({ user, setUser, ipAddress, setIsAlert, setAlertMessage, setAlertSeverity }) {
  const [promptInput, setPromptInput] = useState('');
  const [lyricsInput, setLyricsInput] = useLocalStorage('lyricsInput', '');
  const [artistInput, setArtistInput] = useState('');
  const [genreInput, setGenreInput] = useState('');
  const [result, setResult] = useState('');
  const [improveLyrics, setImproveLyrics] = useState('');
  const [tempInput, setTempInput] = useState(1);
  const [rhymeInput, setRhymeInput] = useState('');
  const [rhymeResult, setRhymeResult] = useState('');
  const [relWordType, setRelWordType] = useState('rhy');
  const [language, setLanguage] = useState('en');
  const [generateButtonStatus, setGenerateButtonStatus] = useState(false);
  const [improveButtonStatus, setImproveButtonStatus] = useState(false);
  const [suggestButtonStatus, setSuggestButtonStatus] = useState(false);
  const [relatedButtonStatus, setRelatedButtonStatus] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const [startsWith, setStartsWith] = useState('');
  const [endsWith, setEndsWith] = useState('');
  const [rhymesWith, setRhymesWith] = useState('');
  const [currLast, setCurrLast] = useState('');
  const [showMore, setShowMore] = useState(true);
  const [resultList, setResultList] = useLocalStorage('lyricResults', []);
  const [resultView, setResultView] = useLocalStorage('resultView', 0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [shareIsOpen, setShareIsOpen] = useState(false);
  const [shareWrittenIsOpen, setShareWrittenIsOpen] = useState(false);
  let [modelChoice, setModelChoice] = useState('');
 

  const navigate = useNavigate();


  const [calls, setCalls] = useLocalStorage('calls', 0);

  
  const rhymeColumns = React.useMemo(
    () => [
      {
        Header: 'Results',
        isVisible: false,
        hideHeader: false,
        columns: [
          {
            Header: 'Word',
            accessor: 'word',
          },
          {
            Header: 'Popularity',
            accessor: 'score',
          },
          {
            Header: '# Syllables',
            accessor: 'numSyllables',
          },
        ]
      },
    ],
    []
  )

  const generateUrl = user ? "https://frozen-springs-61240.herokuapp.com/generateAuthenticated" : "https://frozen-springs-61240.herokuapp.com/generate";
  // const generateUrl = user ? "http://localhost:5001/generateAuthenticated" : "http://localhost:5001/generate";

  const apiHeaders = user ? { "Content-Type": "application/json", "x-access-token": user.token} : { "Content-Type": "application/json"};

  const handleModalOpen = () => {
    setModalIsOpen(true);
  }

  const handleModalClose = () => {
    setModalIsOpen(false);
  }

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains('modal-container')) {
      handleModalClose();
      handleShareClose();
      handleShareWrittenClose();
    }
  }

  const handleShareOpen = () => {
    setShareIsOpen(true);
  }

  const handleShareClose = () => {
    setShareIsOpen(false);
  }

  const handleShareWrittenOpen = () => {
    setShareWrittenIsOpen(true);
  }

  const handleShareWrittenClose = () => {
    setShareWrittenIsOpen(false);
  }

  function ErrorFallback({error, resetErrorBoundary}) {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
      </div>
    )
  }

  async function onSubmit(event) {
    setGenerateButtonStatus(true);
    event.preventDefault();
    let response;
    try{
      response = await fetch(generateUrl, {
        method: "POST",
        mode: 'cors',
        headers: apiHeaders,
        body: JSON.stringify({ prompt: promptInput , artist: artistInput, genre: genreInput, language: language, temp: tempInput, isLyrics: false, isGenerate: true, isImprove: false, isSuggest: false, ipAddress, user }),
      })

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let result = '';
      const oldResultList = resultList;

      while(true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        const chunk = decoder.decode(value);
        const lines = chunk.split("\n");
        const parsedLines = lines
          .map((line) => line.replace(/^data: /, "").trim()) // Remove the "data: " prefix
          .filter((line) => line !== "" && line !== "[DONE]") // Remove empty lines and "[DONE]"
          .map((line) => JSON.parse(line.trim())); // Parse the JSON string      }

        for (const parsedLine of parsedLines){
          const { choices } = parsedLine;
          const { delta } = choices[0];
          const { content } = delta;

          if (content) {
            result += content;
            result ? setResultList(arr => [...oldResultList, result.trim()]) : setResultList("");
            result ? setResultView(resultList.length) : setResultView(resultView.trim());
          }
        }
        setLyricsInput("");
      }

    } catch (error) {
      console.log(error);
      setLyricsInput("Sorry, an error occurred. Please try again later or report this with our chat box in the bottom right corner.")
      if (response?.status === 402){
        if(!user) {        
          setAlertSeverity("error");
          setIsAlert(true);
          setAlertMessage("You have exceeded your credit limit. Please sign up for more credits.");
          navigate('/register');
        } else {
          handleModalOpen();
        }
      } else if (response?.status === 403) {
      setResult("Please Login again");
    }
    }
    finally {
      setGenerateButtonStatus(false);
    }
  }

  async function onSubmitLyrics(event) {
    setImproveButtonStatus(true);
    event.preventDefault();
    let response;
    try {
      response = await fetch(generateUrl, {
        method: "POST",
        mode: 'cors',
        headers: apiHeaders,
        body: JSON.stringify({ prompt: lyricsInput, artist: artistInput, genre: genreInput, language: language, improveLyrics: improveLyrics, temp: tempInput, isLyrics: true, isGenerate: false, isImprove: true, isSuggest: false, ipAddress, user }),
      });


      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let result = '';
      const oldResultList = resultList;

      while(true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        const chunk = decoder.decode(value);
        const lines = chunk.split("\n");
        const parsedLines = lines
          .map((line) => line.replace(/^data: /, "").trim()) // Remove the "data: " prefix
          .filter((line) => line !== "" && line !== "[DONE]") // Remove empty lines and "[DONE]"
          .map((line) => JSON.parse(line.trim())); // Parse the JSON string      }

        for (const parsedLine of parsedLines){
          const { choices } = parsedLine;
          const { delta } = choices[0];
          const { content } = delta;

          if (content) {
            result += content;
            result ? setResultList(arr => [...oldResultList, result.trim()]) : setResultList("");
            result ? setResultView(resultList.length) : setResultView(resultView.trim());
          }
        }
      }
            
      if (response?.status === 402) {
        if(!user) {        
          setAlertSeverity("error");
          setIsAlert(true);
          setAlertMessage("You have exceeded your credit limit. Please sign up for more credits.");
          navigate('/register');
        } else {
          handleModalOpen();
        }
      } else if (response?.status === 403) {
        setResult("Please Login again");
      }
    } catch (error) {
      console.log(error);
      setResult("Sorry, an error occurred. Please try again later or report this with our chat box in the bottom right corner.")
      if (response?.status === 402){
        if(!user) {        
          setAlertSeverity("error");
          setIsAlert(true);
          setAlertMessage("You have exceeded your credit limit. Please sign up for more credits.");
          navigate('/register');
        } else {
          handleModalOpen();
        }
      } else if (response?.status === 403) {
        setResult("Please Login again");
    }
    }
    finally {
      setImproveButtonStatus(false);
    }
  }

  async function onSuggestLyrics(event) {
    setSuggestButtonStatus(true);
    event.preventDefault();
    let response;
    try {
      response = await fetch(generateUrl, {
        method: "POST",
        mode: 'cors',
        headers: apiHeaders,
        body: JSON.stringify({ line: currLast, startsWith: startsWith, endsWith: endsWith, rhymesWith: rhymesWith, language: language, temp: tempInput, isGenerate: false, isLyrics: false, isImprove: false, isSuggest: true, ipAddress, user }),
      });
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let result = '';
      const oldResultList = resultList;

      while(true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        const chunk = decoder.decode(value);
        const lines = chunk.split("\n");
        const parsedLines = lines
          .map((line) => line.replace(/^data: /, "").trim()) // Remove the "data: " prefix
          .filter((line) => line !== "" && line !== "[DONE]") // Remove empty lines and "[DONE]"
          .map((line) => JSON.parse(line.trim())); // Parse the JSON string      }

        for (const parsedLine of parsedLines){
          const { choices } = parsedLine;
          const { delta } = choices[0];
          const { content } = delta;

          if (content) {
            result += content;
            result ? setResultList(arr => [...oldResultList, result.trim()]) : setResultList("");
            result ? setResultView(resultList.length) : setResultView(resultView.trim());
          }
        }
      }

      if (response?.status === 402) {
        if(!user) {        
          setAlertSeverity("error");
          setIsAlert(true);
          setAlertMessage("You have exceeded your credit limit. Please sign up for more credits.");
          navigate('/register');
        } else {
          handleModalOpen();
        }
      } else if (response?.status === 403) {
        setResult("Please Login again");
      }
    } catch (error) {
      console.log(error);
      setResult("Sorry, an error occurred. Please try again later or report this with our chat box in the bottom right corner.")
      if (response?.status === 402){
        if(!user) {        
          setAlertSeverity("error");
          setIsAlert(true);
          setAlertMessage("You have exceeded your credit limit. Please sign up for more credits.");
          navigate('/register');
        } else {
          handleModalOpen();
        }
      } else if (response?.status === 403) {
        setResult("Please Login again");
    }
    }
    finally {
      setSuggestButtonStatus(false);
    }
  }

  async function onSubmitRhyme(event) {
    setRelatedButtonStatus(true);
    event.preventDefault();
    try {
      let url = 'https://api.datamuse.com/words?rel_' + relWordType + '=' + rhymeInput + '&max=1000'
      const response = await fetch(url);
      const data = await response.text();
      data ? setRhymeResult(data) : setRhymeResult("Sorry, an error occurred. Please try again\\nlater or report this with our chat\\nbox in the bottom right corner.");
    } catch (error) {
      console.log(error);
      setRhymeResult("Sorry, an error occurred. Please try again later or report this with our chat box in the bottom right corner.")
    }
    finally {
      setRelatedButtonStatus(false);
    }
  }

  function Table({ columns, data }) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable(
      {
        columns:columns,
        data:data,
      },
      useSortBy
    )
  
    // const firstPageRows = rows.slice(0, rowsPerPage)

    return (
      <>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {   
                return column.hideHeader === false ? null : (               
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="flex items-center">
                      {column.render('Header')}
                      <a href="#"><svg xmlns="http://www.w3.org/2000/svg" className="ml-1 w-3 h-3" aria-hidden="true" fill="currentColor" viewBox="0 0 320 512"><path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"/></svg></a>
                    </div>
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' '
                          : ' '
                        : ''}
                    </span>
                  </th>);
                  }

                )}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(
              (row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      )
                    })}
                  </tr>
                )}
            )}
          </tbody>
        </table>
        <br />
      </>
    )
  }

  function TableCondition(props) {
    if (props.value) {   
      if (props.value === "[]") {
        return "No results found.";
      } else {
        return <Table columns={rhymeColumns} data={isJson(rhymeResult) ? JSON.parse(rhymeResult) : null}/>;
      }
    } else {
      return "";
    }
  }

  function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  const copyToClipBoard = async copyMe => {
    try {
      await navigator.clipboard.writeText(copyMe);
    } catch (err) {
      console.log('Failed to copy!');
    }
  };

  function ClipIconAppear(props) {
    if (props.value) {
      if (props.value === "") {
        return;
      } else {
        return <button type="hover" className = "top-3 right-3" onClick={() => copyToClipBoard(props.value)}><ThemeProvider theme={theme}><CopyIcon color = "primary" sx={{ width: "70%" }}/></ThemeProvider></button>;
      }
    }
  }

  function ShareIconAppear(props) {
    if (props.value) {
      if (props.value === "") {
        return;
      } else {
        return <button type="hover" className = "top-3 right-3" onClick={() => handleShareOpen()}><ThemeProvider theme={theme}><ShareIcon color = "primary" sx={{ width: "70%" }}/></ThemeProvider></button>;
      }
    }
  }

  function ShareIconAppearWritten(props) {
    if (props.value) {
      if (props.value === "") {
        return;
      } else {
        return <button type="hover" className = "top-3 right-3" onClick={() => handleShareWrittenOpen()}><ThemeProvider theme={theme}><ShareIcon color = "primary" sx={{ width: "70%" }}/></ThemeProvider></button>;
      }
    }
  }

  function ArrowsAppear() {
    if (resultList.length <= 1) {
      return;
    } else if (resultView === 0 & resultList.length > 1){
      return <div className="mt-4"><div className="grid grid-cols-8 space-x-2 px-4 mb-2"><input className="col-start-1 col-span-2" value = "< Prev" type="submit" name="disabled" disabled/><input className="col-start-3 col-span-2" value = "Next >" type="submit" onClick={() => setResultView(resultView+1)}/></div><div className="text-xs text-white font-bold px-4 mb-6">Showing result: {resultView+1} of {resultList.length}</div></div>;
    } else if (resultView+1 === resultList.length & resultList.length > 1) {
      return <div className="mt-4"><div className="grid grid-cols-8 space-x-2 px-4 mb-2"><input className="col-start-1 col-span-2" value = "< Prev" type="submit" onClick={() => setResultView(resultView-1)}/><input className="col-start-3 col-span-2" value = "Next >" type="submit" name="disabled" disabled/></div><div className="text-xs text-white font-bold px-4 mb-6">Showing result: {resultView+1} of {resultList.length}</div></div>;
    } else {
      return <div className="mt-4"><div className="grid grid-cols-8 space-x-2 px-4 mb-2"><input className="col-start-1 col-span-2" value = "< Prev" type="submit" onClick={() => setResultView(resultView-1)}/><input className="col-start-3 col-span-2" value = "Next >" type="submit" onClick={() => setResultView(resultView+1)}/></div><div className="text-xs text-white font-bold px-4 mb-6">Showing result: {resultView+1} of {resultList.length}</div></div>;
    }
  }

  return (
    <div type="body" name="Homescreen">

      {modalIsOpen && (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <Modal user={user} onClose={handleModalClose} />
        </div>
      )}

      {shareIsOpen && (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <ShareModal text={resultList.at(resultView)} onClose={handleShareClose} />
        </div>
      )}

      {shareWrittenIsOpen && (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <ShareModal text={lyricsInput} onClose={handleShareWrittenClose} />
        </div>
      )}

        <main className={styles.main}>
          {/* Choose model selector */}

          <Container className="content-between min-h-screen" disableGutters={true} maxWidth="full" max = "full">

            <div className="row-auto content-between flex-wrap lg:grid lg:grid-cols-16 lg:gap-0 md:ml-24" id = "contents">
              <div className="m-5 mx-8 px-5 py-5 rounded-md lg:col-start-1 lg: col-span-2 lg:mr-0 lg:rounded-r-none" id="col1">
              <Select
                size="small"
                sx={{ 
                  backgroundColor: 'transparent',
                  color: "gray", 
                  fontSize: '0.875rem', // Smaller font size
                  '& .MuiSelect-select': {
                    paddingTop: '4px',
                    paddingBottom: '4px',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(255, 255, 255, 0.3)', // Subtle border color
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(255, 255, 255, 0.5)', // Slightly more visible on hover
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white', // Visible border when focused
                  },
                  '& .MuiSvgIcon-root': {
                    color: 'gray', // Arrow icon color
                  },
                }}
                className="w-fit mb-4"
                value={modelChoice}
                onChange={(event) => {
                  const selectedOption = event.target.value;
                  if (selectedOption === 'advanced' && !(user?.customer?.stripeSubscriptionStatus === 'active' || user?.oneOffCustomer)) {
                    setIsAlert(true);
                    setAlertMessage("You need to have an active plan to use this model.");
                    setAlertSeverity("error");
                    setModelChoice('basic');
                  } else {
                    setModelChoice(selectedOption);
                  }
                }}
                displayEmpty
                renderValue={(value) => {
                  if (value === '') {
                    return <em>Choose Model</em>;
                  }
                  return value.charAt(0).toUpperCase() + value.slice(1);
                }}
              >
                <MenuItem value="" disabled>
                  <em>Choose Model</em>
                </MenuItem>
                <MenuItem value="basic">Basic</MenuItem>
                <MenuItem value="advanced">Advanced</MenuItem>
              </Select>
              <div type = "headers">Write me a song about...</div>
              <h1 className = "text-white/50 text-sm">Use the fields below to generate new lyrics</h1>
                <form onSubmit={onSubmit}>

                <Tooltip
                title={<span style={{ whiteSpace: 'pre-line' }}>{"Write what you want the song to be about. You can choose multiple topics and be as verbose as you like.\n\n Example: heartbreak, sunshine, pizza"}</span>}
                placement="right">
                  <input
                    type="text"
                    autoComplete="off"
                    id="prompt"
                    name="prompt"
                    placeholder="Topic"
                    value={promptInput}
                    onChange={(e) => {
                      setPromptInput(e.target.value)
                    }}
                    required
                  />
                </Tooltip>

                <Tooltip
                title={<span style={{ whiteSpace: 'pre-line' }}>{"What style of artist would you like the lyrics to be in?\n\n Example: Taylor Swift"}</span>}
                placement="right">                
                  <input
                    type="text"
                    autoComplete="off"
                    id="prompt"
                    name="prompt"
                    placeholder="Artist (optional)"
                    value={artistInput}
                    onChange={(e) => {
                      setArtistInput(e.target.value)
                    }}
                  />
                </Tooltip>

                <Tooltip
                title={<span style={{ whiteSpace: 'pre-line' }}>{"What style of genre would you like the lyrics to fit (e.g. Pop, Rock, Rap, etc.)?\n\nIf you have a more niche genre in mind, you can write that too!\n\nExamples: Dream Pop, Cloud Rap, 90s Hip-Hop"}</span>}
                placement="right">      
                  <input
                    type="text"
                    autoComplete="off"
                    id="prompt"
                    name="prompt"
                    placeholder="Genre (optional)"
                    value={genreInput}
                    onChange={(e) => {
                      setGenreInput(e.target.value)
                    }}
                  />
                </Tooltip>

                <div type = "headers">Creativity: {Math.floor(parseFloat(tempInput)*100)}%</div>
                <Tooltip
                title={<span style={{ whiteSpace: 'pre-line' }}>{"How creative do you want the lyrics to be?\n\nUsing higher creativity will make the lyrics more random, and using lower creativity will use more standard language."}</span>}
                placement="right">
                <input
                  type="range"
                  id="randomness"
                  name="randomness"
                  className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                  min="0" max="1" step="0.01"
                  onChange={(e) => {
                    setTempInput(e.target.value)
                  }}
                />
                </Tooltip>
           
                <div className="grid grid-cols-8 gap-0">

                  <Tooltip
                  title={<span style={{ whiteSpace: 'pre-line' }}>{ "Once the button is clicked, lyrics should appear within a minute.\n\nGenerated lyrics will appear in the inspiration station."}</span>}
                  placement="bottom">
                    <input type="submit" className="col-start-1 col-span-5 rounded-r-none submitButton" name = "generate" value={!generateButtonStatus ? "Generate" : "Loading..."} disabled={generateButtonStatus}/>
                  </Tooltip>

                  <select id="language (Optional) " name="languages" className="col-start-6 col-span-3 rounded-l-none" style={{ marginBottom: "0px"}} onChange={(e) => {
                      setLanguage(e.target.value)
                    }}>
                      <option value="en">English</option>
                      <option value="af">Afrikaans</option>
                      <option value="sq">Albanian - shqip</option>
                      <option value="am">Amharic - አማርኛ</option>
                      <option value="ar">Arabic - العربية</option>
                      <option value="an">Aragonese - aragonés</option>
                      <option value="hy">Armenian - հայերեն</option>
                      <option value="ast">Asturian - asturianu</option>
                      <option value="az">Azerbaijani - azərbaycan dili</option>
                      <option value="eu">Basque - euskara</option>
                      <option value="be">Belarusian - беларуская</option>
                      <option value="bn">Bengali - বাংলা</option>
                      <option value="bs">Bosnian - bosanski</option>
                      <option value="br">Breton - brezhoneg</option>
                      <option value="bg">Bulgarian - български</option>
                      <option value="ca">Catalan - català</option>
                      <option value="ckb">Central Kurdish - کوردی (دەستنوسی عەرەبی)</option>
                      <option value="zh">Chinese - 中文</option>
                      <option value="zh-HK">Chinese (Hong Kong) - 中文（香港）</option>
                      <option value="zh-CN">Chinese (Simplified) - 中文（简体）</option>
                      <option value="zh-TW">Chinese (Traditional) - 中文（繁體）</option>
                      <option value="co">Corsican</option>
                      <option value="hr">Croatian - hrvatski</option>
                      <option value="cs">Czech - čeština</option>
                      <option value="da">Danish - dansk</option>
                      <option value="nl">Dutch - Nederlands</option>
                      <option value="eo">Esperanto - esperanto</option>
                      <option value="et">Estonian - eesti</option>
                      <option value="fo">Faroese - føroyskt</option>
                      <option value="fil">Filipino</option>
                      <option value="fi">Finnish - suomi</option>
                      <option value="fr">French - français</option>
                      <option value="fr-CA">French (Canada) - français (Canada)</option>
                      <option value="fr-FR">French (France) - français (France)</option>
                      <option value="fr-CH">French (Switzerland) - français (Suisse)</option>
                      <option value="gl">Galician - galego</option>
                      <option value="ka">Georgian - ქართული</option>
                      <option value="de">German - Deutsch</option>
                      <option value="de-AT">German (Austria) - Deutsch (Österreich)</option>
                      <option value="de-DE">German (Germany) - Deutsch (Deutschland)</option>
                      <option value="de-LI">German (Liechtenstein) - Deutsch (Liechtenstein)</option>
                      <option value="de-CH">German (Switzerland) - Deutsch (Schweiz)</option>
                      <option value="el">Greek - Ελληνικά</option>
                      <option value="gn">Guarani</option>
                      <option value="gu">Gujarati - ગુજરાતી</option>
                      <option value="ha">Hausa</option>
                      <option value="haw">Hawaiian - ʻŌlelo Hawaiʻi</option>
                      <option value="he">Hebrew - עברית</option>
                      <option value="hi">Hindi - हिन्दी</option>
                      <option value="hu">Hungarian - magyar</option>
                      <option value="is">Icelandic - íslenska</option>
                      <option value="id">Indonesian - Indonesia</option>
                      <option value="ia">Interlingua</option>
                      <option value="ga">Irish - Gaeilge</option>
                      <option value="it">Italian - italiano</option>
                      <option value="it-IT">Italian (Italy) - italiano (Italia)</option>
                      <option value="it-CH">Italian (Switzerland) - italiano (Svizzera)</option>
                      <option value="ja">Japanese - 日本語</option>
                      <option value="kn">Kannada - ಕನ್ನಡ</option>
                      <option value="kk">Kazakh - қазақ тілі</option>
                      <option value="km">Khmer - ខ្មែរ</option>
                      <option value="ko">Korean - 한국어</option>
                      <option value="ku">Kurdish - Kurdî</option>
                      <option value="ky">Kyrgyz - кыргызча</option>
                      <option value="lo">Lao - ລາວ</option>
                      <option value="la">Latin</option>
                      <option value="lv">Latvian - latviešu</option>
                      <option value="ln">Lingala - lingála</option>
                      <option value="lt">Lithuanian - lietuvių</option>
                      <option value="mk">Macedonian - македонски</option>
                      <option value="ms">Malay - Bahasa Melayu</option>
                      <option value="ml">Malayalam - മലയാളം</option>
                      <option value="mt">Maltese - Malti</option>
                      <option value="mr">Marathi - मराठी</option>
                      <option value="mn">Mongolian - монгол</option>
                      <option value="ne">Nepali - नेपाली</option>
                      <option value="no">Norwegian - norsk</option>
                      <option value="nb">Norwegian Bokmål - norsk bokmål</option>
                      <option value="nn">Norwegian Nynorsk - nynorsk</option>
                      <option value="oc">Occitan</option>
                      <option value="or">Oriya - ଓଡ଼ିଆ</option>
                      <option value="om">Oromo - Oromoo</option>
                      <option value="ps">Pashto - پښتو</option>
                      <option value="fa">Persian - فارسی</option>
                      <option value="pl">Polish - polski</option>
                      <option value="pt">Portuguese - português</option>
                      <option value="pt-BR">Portuguese (Brazil) - português (Brasil)</option>
                      <option value="pt-PT">Portuguese (Portugal) - português (Portugal)</option>
                      <option value="pa">Punjabi - ਪੰਜਾਬੀ</option>
                      <option value="qu">Quechua</option>
                      <option value="ro">Romanian - română</option>
                      <option value="mo">Romanian (Moldova) - română (Moldova)</option>
                      <option value="rm">Romansh - rumantsch</option>
                      <option value="ru">Russian - русский</option>
                      <option value="gd">Scottish Gaelic</option>
                      <option value="sr">Serbian - српски</option>
                      <option value="sh">Serbo-Croatian - Srpskohrvatski</option>
                      <option value="sn">Shona - chiShona</option>
                      <option value="sd">Sindhi</option>
                      <option value="si">Sinhala - සිංහල</option>
                      <option value="sk">Slovak - slovenčina</option>
                      <option value="sl">Slovenian - slovenščina</option>
                      <option value="so">Somali - Soomaali</option>
                      <option value="st">Southern Sotho</option>
                      <option value="es">Spanish - español</option>
                      <option value="es-AR">Spanish (Argentina) - español (Argentina)</option>
                      <option value="es-419">Spanish (Latin America) - español (Latinoamérica)</option>
                      <option value="es-MX">Spanish (Mexico) - español (México)</option>
                      <option value="es-ES">Spanish (Spain) - español (España)</option>
                      <option value="es-US">Spanish (United States) - español (Estados Unidos)</option>
                      <option value="su">Sundanese</option>
                      <option value="sw">Swahili - Kiswahili</option>
                      <option value="sv">Swedish - svenska</option>
                      <option value="tg">Tajik - тоҷикӣ</option>
                      <option value="ta">Tamil - தமிழ்</option>
                      <option value="tt">Tatar</option>
                      <option value="te">Telugu - తెలుగు</option>
                      <option value="th">Thai - ไทย</option>
                      <option value="ti">Tigrinya - ትግርኛ</option>
                      <option value="to">Tongan - lea fakatonga</option>
                      <option value="tr">Turkish - Türkçe</option>
                      <option value="tk">Turkmen</option>
                      <option value="tw">Twi</option>
                      <option value="uk">Ukrainian - українська</option>
                      <option value="ur">Urdu - اردو</option>
                      <option value="ug">Uyghur</option>
                      <option value="uz">Uzbek - o‘zbek</option>
                      <option value="vi">Vietnamese - Tiếng Việt</option>
                      <option value="wa">Walloon - wa</option>
                      <option value="cy">Welsh - Cymraeg</option>
                      <option value="fy">Western Frisian</option>
                      <option value="xh">Xhosa</option>
                      <option value="yi">Yiddish</option>
                      <option value="yo">Yoruba - Èdè Yorùbá</option>
                      <option value="zu">Zulu - isiZulu</option>
                  </select>

                </div>
                </form>
              </div> 
              <div className="m-5 mx-8 px-5 py-5 rounded-md lg:col-start-3 lg:col-span-10 lg:ml-0 lg:mr-3.5 lg:rounded-l-none" id="col2">

                <div className="grid grid-cols-1 lg:grid-cols-8 lg:gap-0">
                  <div className="lg:col-start-1 lg:col-span-4">
                    <div className="grid grid-cols-8">
                      <div type="headers" className = "col-start-1 col-span-4 pl-4"> Inspiration Station </div>
                      <div className="col-start-7 col-span-1"><ClipIconAppear value={resultList.at(resultView)} x="0"/></div>
                      <div className="col-start-8 col-span-1">
                        <ShareIconAppear value={resultList.at(resultView)} x="0"/>
                        <small style={{color: 'lightgrey'}}>Share</small>
                        </div>
                    </div>
                    <div type = "container" className = "relative">
                      <textarea
                        type="text"
                        name="fixed"
                        rows="20"
                        cols="55"
                        placeholder="Generated lyrics will appear here."
                        value={resultList.at(resultView)}
                        disabled
                        contentEditable
                      ></textarea>
                    </div>
                      <ArrowsAppear value={resultList}/>
                  </div>
                  <div className="lg:col-start-5 lg:col-span-4">
                    <div className="grid grid-cols-8">
                      <div type="headers" className = "col-start-1 col-span-4 pl-4"> Writing Station </div>
                      <div className="col-start-7 col-span-1"><ClipIconAppear value={lyricsInput} x="0"/></div>
                      <div className="col-start-8 col-span-1"><ShareIconAppearWritten value={lyricsInput} x="0"/></div>
                    </div>
                  <form onSubmit={onSubmitLyrics}>
                    <div type = "container" className = "relative">
                      <textarea
                      type="text"
                      name="lyrics"
                      rows="14"
                      cols="20"
                      placeholder="Write/edit lyrics here"
                      onChange={(e) => {
                        setLyricsInput(e.target.value)
                        setCurrLast(e.target.value.split('\n').filter(function(e){return e}).slice(-1))
                      }}
                      value={lyricsInput}
                      required
                      >{lyricsInput}</textarea>
                    </div>
                      <div type = "headers"> Improve these lyrics by: </div>

                      <Tooltip
                      title={<span style={{ whiteSpace: 'pre-line' }}>{"Use this tool to rewrite and optimize your lyrics based on a specific command. Simply enter your lyrics into the input field and specify the command you want the AI to follow, such as 'improve word choice' or 'add more detail'.\n\nThe function will then analyze your lyrics and rewrite them to better meet your specified objective."}</span>}
                      placement="right">
                        <input
                        type="text"
                        autoComplete="off"
                        id="prompt"
                        name="lyricsPrompt"
                        placeholder="making it rhyme better, making it more catchy, etc."
                        value={improveLyrics}
                        onChange={(e) => {
                          setImproveLyrics(e.target.value)
                        }}
                        />
                      </Tooltip>                 
                      <input style={{ marginBottom: "24px"}} className="submitButton" type="submit" value={!improveButtonStatus ? "Improve Lyrics" : "Loading..."} disabled={improveButtonStatus}/>
                    </form>
                  </div>

                </div>                  
              </div>
              <div className="m-5 mx-8 rounded-md lg:col-start-13 lg:col-span-3 lg:ml-0" id="col3">
              <div name="SuggestedLines" className="rounded-md px-5 py-5">
                  <div type = "headers">Next Line Suggestions</div>

                  <form onSubmit={onSuggestLyrics}>
                    <div className="relative">
                    <Tooltip
                      title={<span style={{ whiteSpace: 'pre-line' }}>{"This field should contain the line which you want suggestions for next lines to be based on.\n\nBy default, it will use the last line currently written in the Writing Station, but you can change it by typing here."}</span>}
                      placement="left">
                        <input
                        type="text"
                        autoComplete="off"
                        id="lastLine"
                        name="prompt"
                        className="block px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-white/0 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-indigo-500 focus:outline-none focus:ring-0 focus:border-indigo-600 peer"
                        value={currLast}
                        onChange={(e) => {
                          setCurrLast(e.target.value)
                        }}
                        required
                        />
                      </Tooltip> 

                      <label for="lastLine" className="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 origin-[0] left-1.5 peer-focus:text-indigo-600 peer-focus:dark:text-indigo-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">Last Line:</label>
                    </div>
                    <p className="btn text-white/60 text-sm text-left font-bold mb-3 hover:text-indigo-300 hover:underline" onClick={() => setShowMore(!showMore)}>{ showMore ? "Show advanced options..." : "Hide advanced options..."}</p>
                    {showMore ? <></> : <>
                          <Tooltip
                            title={<span style={{ whiteSpace: 'pre-line' }}>{"Write the word/phrase you want the next line to start with."}</span>}
                            placement="left">
                            <input
                              type="text"
                              autoComplete="off"
                              id = "startsWith"
                              name="prompt"
                              placeholder="Start next line with (optional)"
                              value={startsWith}
                              onChange={(e) => {  
                                setStartsWith(e.target.value)
                              }}
                            />
                            </Tooltip> 

                            <Tooltip
                            title={<span style={{ whiteSpace: 'pre-line' }}>{"Write the word/phrase you want the next line to end with."}</span>}
                            placement="left">
                            <input
                              type="text"
                              autoComplete="off"
                              id = "endsWith"
                              name="prompt"
                              placeholder="End next line with (optional)"
                              value={endsWith}
                              onChange={(e) => {  
                                setEndsWith(e.target.value)
                              }}
                            />
                            </Tooltip>                             

                            <Tooltip
                            title={<span style={{ whiteSpace: 'pre-line' }}>{"Write the word/phrase you want the next line to rhyme with."}</span>}
                            placement="left">
                            <input
                              type="text"
                              autoComplete="off"
                              id = "rhymesWith"
                              name="prompt"
                              placeholder="Rhyme next line with (optional)"
                              value={rhymesWith}
                              onChange={(e) => {  
                                setRhymesWith(e.target.value)
                              }}
                            />
                            </Tooltip>                    
                        </>                           
                    }

                    <input style={{ marginBottom: "40px", marginTop: "5px"}} className="submitButton" type="submit" value={!suggestButtonStatus ? "Suggest Lyrics" : "Loading..."} disabled={suggestButtonStatus} />


                  </form>
                </div>
                <div name="RelatedWords" className="rounded-md mt-4 px-5 py-5">
                <form onSubmit={onSubmitRhyme}>
                <div type = "headers"> Related Word Search</div>
                
                <div className="grid grid-cols-8 gap-0">

                <Tooltip
                title={<span style={{ whiteSpace: 'pre-line' }}>{"This tool allows you to find words/phrases that are related to a specific word.\n\nSimply enter a word into the input field and the type of relation you're looking for, and it will return a list of related words that you can use in your lyrics."}</span>}
                placement="left">
                  <input
                      type="text"
                      autoComplete="off"
                      id = "relWord"
                      name="prompt"
                      className="col-start-1 col-span-4"
                      placeholder="Enter word here"
                      value={rhymeInput}
                      onChange={(e) => {  
                        setRhymeInput(e.target.value)
                      }}
                      required
                    />
                  </Tooltip>  

                  <select name="relatedOption" className="col-start-5 col-span-4" onChange={(e) => {
                      setRelWordType(e.target.value)
                    }}>
                    <option value="rhy">Exact Rhymes</option>
                    <option value="nry">Near Rhymes</option>
                    <option value="cns">Consonant Match</option>
                    <option value="hom">Homophones</option>
                    <option value="syn">Synonyms</option>
                    <option value="ant">Antonyms</option>
                    <option value="jja">Popular Nouns</option>
                    <option value="jjb">Popular Adjectives</option>
                    <option value="bga">Frequent Followers</option>
                    <option value="bgb">Frequent Predecessors</option>
                    <option value="trg">Associated With</option>
                  </select>
                  
                  </div>

                <input style={{ marginBottom: "24px"}} className="submitButton" type="submit" value={!relatedButtonStatus ? "Find Related Words" : "Loading..."} disabled={relatedButtonStatus} />
                </form>
                <div id="table-container">
                  <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onReset={() => {
                    setRhymeResult([]);
                  }}
                  >
                    <TableCondition value={rhymeResult}/>
                  </ErrorBoundary>
                </div>
                </div>                
              </div>
            </div>
          </Container>
        </main>
        <Footer/>
    </div>
  );
}

